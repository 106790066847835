<template>
  <EditEntity
    :fields="fields"
    :save-url="saveUrl"
    :get-url="getUrl"
    :id="$route.params.id"
    @updateIsDataSaved="updateIsDataSaved"
  ></EditEntity>
</template>
<script>
import { defineComponent } from "vue";
import EditEntity from "@/components/EditEntity";

export default defineComponent({
  name: "article",
  components: {
    EditEntity,
  },
  data: function () {
    return {
      saveUrl: "api/admin/article/save",
      getUrl: "admin/article/",
      isDataSaved: true,

      fields: [
        {
          name: "Active",
          value: "active",
          type: "boolean",
        },
        {
          name: "Article Type",
          value: "articleType",
          type: "select",
          options: [
            {
              value: 0,
              name: "Main",
            },
            {
              value: 1,
              name: "Article",
            },
            {
              value: 2,
              name: "Other",
            },
          ],
        },
        {
          name: "Author",
          value: "author",
        },
        {
          name: "Title",
          value: "title",
          type: "input-i18n",
        },
        {
          name: "Transliterated title (displays in URL!)",
          value: "transliteratedTitle",
        },
        {
          name: "Date",
          value: "createdDate",
          type: "datepicker",
        },
        {
          name: "Preview Content",
          value: "shortContent",
          type: "wysiwyg-i18n",
        },
        {
          name: "Content",
          value: "content",
          type: "wysiwyg-i18n",
        },
      ],
    };
  },

  mounted() {
    window.addEventListener("beforeunload", this.beforeUnload);
  },

  beforeUnmount() {
    window.removeEventListener("beforeunload", this.beforeUnload); // Очистите обработчик, чтобы избежать утечек памяти
  },

  beforeRouteLeave(to, from, next) {
    if (!this.isDataSaved) {
      const answer = window.confirm(
        "У вас есть несохраненные изменения. Вы уверены, что хотите уйти?"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },

  methods: {
    beforeUnload(event) {
      if (!this.isDataSaved) {
        const message =
          "У вас есть несохраненные изменения. Вы уверены, что хотите покинуть страницу?";
        event.returnValue = message; // Стандартное сообщение для разных браузеров
        return message; // Некоторые браузеры требуют возврата сообщения
      }
    },
    updateIsDataSaved: function (newValue) {
      this.isDataSaved = newValue;
    },
  },
});
</script>
